<template>
  <!-- 艾灸穴位 -->
  <div name="aijiuchild" class="aiji">
    <div class="flex-row align-items overflow">
      <div
        @click="btnClik(index, 11)"
        v-for="(item, index) in datalist"
        :key="index"
        class="newre-read-txt"
        :class="{
          active: btnIndex == index,
        }"
        v-show="item.title"
      >
        {{ item.title }}
      </div>
    </div>
    <div
      v-for="(item, index) in datalist"
      :key="index"
      v-show="btnIndex == index"
    >
      <div class="newre-zh-box newre-background">
        <div class="newre-zh-title">穴位位置</div>
        <div class="newre-bottom">{{ item.location }}</div>

        <div class="newre-zh-title">功效</div>
        <div class="newre-bottom">{{ item.function }}</div>

        <div class="newre-zh-title">时长</div>
        <div class="newre-bottom">{{ item.period }}</div>
        <img class="food-img" :src="asset_prefix + item.images" alt="" />
      </div>
    </div>
  </div>
  <div class="hu-ask" v-if="cpage==cIndex">
      <div class="ask-tea flex">
        <div
          @click="btnClik(index, 11)"
          v-for="(item, index) in datalist"
          :key="index"
          :class="{
            active: btnIndex == index,
          }"
          v-show="item.title"
          class="syndrome-name syndrome-tea"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        v-for="(item, index) in datalist"
        :key="index"
        v-show="btnIndex == index"
      >
        <div>
          <img :src="asset_prefix + item.images" alt="" class="ask-png" />
          <div class="ask-explain flex">
              <div class="ask-explain-title">穴位位置</div>
              <div class="ask-explain-text flex-1">{{ item.location }}</div>
          </div>
          <div class="ask-explain flex">
              <div class="ask-explain-title">功效</div>
              <div class="ask-explain-text flex-1">{{ item.function }}</div>
          </div>
          <div class="ask-explain flex">
              <div class="ask-explain-title">时长</div>
              <div class="ask-explain-text flex-1">{{ item.period }}</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  props: ["datalist", "asset_prefix" , "cpage" , "cIndex"],
  data() {
    return {
      btnIndex: 0,
    };
  },
  computed: {},
  methods: {
    btnClik(index, type) {
      if (type == 11) {
        this.btnIndex = index;
      }
    },
  },
};
</script>
