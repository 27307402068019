<template>
  <div class="rd-plan-item aiji">
    <div class="newre-tl-btn-box flex-row align-items overflow">
      <div
        class="newre-read-txt"
        :class="{ active: teaIndex == index }"
        v-for="(item, index) in datalist"
        v-show="item.title"
        :key="index"
        @click="teaInd(index)"
      >
        {{ item.title }}
      </div>
    </div>

    <div
      class="pad_2"
      v-for="(item, index) in datalist"
      :key="index"
      v-show="teaIndex == index"
    >
      <div>
        <div class="newre-zh-box newre-background">
          <div class="newre-bottom">{{ item.modern_medicine }}</div>
          <!-- 图片swiper -->
          <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="item in image(item.images)" :key="item.id">
              <img class="rd-teap-img" :src="asset_prefix + '/' + item" alt="">
            </van-swipe-item>
          </van-swipe> -->
          <div class="swipe-img">
            <img class="rd-teap-img" v-for="item in image(item.images)" :key="item.id" :src="asset_prefix + '/' + item" alt="">
          </div>
        </div>
        <div class="newre-zh-box newre-background">
          <!-- 最佳饮用季节 -->
          <div class="newre-zh-title">最佳饮用季节</div>
          <div class="newre-bottom">{{ season(item.season) }}</div>
          <!-- 最佳饮用时辰 -->
          <div class="newre-zh-title">最佳饮用时辰</div>
          <div class="newre-bottom">{{ season(item.times) }}</div>
          <!-- 冲泡器皿 -->
          <div class="newre-zh-title">冲泡器皿</div>
          <div class="newre-bottom">{{ season(item.silver) }}</div>
          <!-- 冲泡温度 -->
          <div class="newre-zh-title">冲泡温度</div>
          <div class="newre-bottom">{{ season(item.temperature) }}</div>
          <!-- 存放方式 -->
          <div class="newre-zh-title">存放方式</div>
          <div class="newre-bottom">{{ season(item.leave_with) }}</div>
        </div>
        <!-- 推荐品种 -->
        <div class="newre-zh-box newre-background">
          <div class="newre-zh-title">推荐品种</div>
          <div class="newre-bottom">{{ season(item.breed) }}</div>
        </div>
      </div>
    </div>

  </div>
  <div class="hu-ask" v-if="cpage==cIndex">
      <div class="ask-tea flex">
        <div class="syndrome-name syndrome-tea"
          :class="{ active: teaIndex == index }"
          v-for="(item, index) in datalist"
          v-show="item.title"
          :key="index"
          @click="teaInd(index)"
        >
          {{ item.title }}
        </div>
      </div>
      <div
        v-for="(item, index) in datalist"
        :key="index"
        v-show="teaIndex == index"
      >
        <div class="flex">
          <div class="tea-png">
            <div class="dishes-cook">
              <img v-for="item in image(item.images)" :key="item.id" :src="asset_prefix + '/' + item" alt="">
            </div>
            <div class="effect-content">{{ item.modern_medicine }}</div>
          </div>
          <div class="flex-1">
            <!-- 最佳饮用季节 -->
            <div class="effect-bottom">
              <div class="effect-title">最佳饮用季节</div>
              <div class="effect-content">{{ season(item.season) }}</div>
            </div>
            <!-- 最佳饮用时辰 -->
            <div class="effect-bottom">
              <div class="effect-title">最佳饮用时辰</div>
              <div class="effect-content">{{ season(item.times) }}</div>
            </div>
            <!-- 冲泡器皿 -->
            <div class="effect-bottom">
              <div class="effect-title">冲泡器皿</div>
              <div class="effect-content">{{ season(item.silver) }}</div>
            </div>
            <!-- 冲泡温度 -->
            <div class="effect-bottom">
              <div class="effect-title">冲泡温度</div>
              <div class="effect-content">{{ season(item.temperature) }}</div>
            </div>
            <!-- 存放方式 -->
            <div class="effect-bottom">
              <div class="effect-title">存放方式</div>
              <div class="effect-content">{{ season(item.leave_with) }}</div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  props: ["content", "item", "datalist", "asset_prefix" ,"cpage","cIndex"],
  data() {
    return {
      teaIndex: 0,
    };
  },
  methods: {
    teaInd(index) {
      this.teaIndex = index;
    },
    season(type) {
      return type.replace(/<br>/g, "\n");
    },
    image(content) {
      var arr = [];
      var item = "";
      arr = content.split(",");
      return arr;
    },
  
  },
};
</script>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  /* background-color: #39a9ed; */
}
</style>