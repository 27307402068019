<template>
<div :class="{white:white,black:!white}">
  <div class="new-bg">
    <!-- <div class="fixed-box">
      <van-nav-bar
        title="健康报告"
        left-text=""
        @click-left="onClickLeft"
        fixed
      />
    </div> -->
    <!-- 健康分数 -->
    <div class="report-content">
      <div class="newre-box">
        <div class="use-mess flex-row">
          <p>性别 : {{ sex == 2 ? "女" : "男" }}</p>
          <div class="line"></div>
          <p>年龄 : {{ birth?birth+"岁":"暂无数据"}}</p>
          <div class="line"></div>
          <p>身高 : {{ height }}cm</p>
        </div>
        <div>
          <div class="flex-row align-items" style="margin-top: 2rem">
            <div>
              <span
                class="newre-orange newre-yellow"
                :class="[
                  { 'newre-red': score <= 40 },
                  { 'newre-great': score >= 80 },
                ]"
                >{{ score }}</span
              >
              <span class="newre-black">分</span>
            </div>
          </div>
          <div class="newre-classify">
            <div>
              <span class="newre-red newre-width"> 0-50分&nbsp;&nbsp;</span
              >可能患有疾病，建议<span class="newre-red">就医检查</span>
            </div>
            <div>
              <span class="newre-orange2 newre-width">50-65分&nbsp;</span
              >身体极不平衡，建议<span class="newre-orange2">马上调理</span>
            </div>
            <div>
              <span class="newre-ching newre-width">65-80分&nbsp;</span
              >身体较不平衡，建议<span class="newre-ching">马上调理</span>
            </div>
            <div>
              <span class="newre-great newre-width">80-90分&nbsp;</span
              >身体较为平衡，建议<span class="newre-great">适当调理</span>
            </div>
            <div>
              <span class="newre-greet newre-width">90-100分 </span
              >身体平衡，建议<span class="newre-greet">适当调理</span>
            </div>
          </div>
          <div>
            <div class="newr-title">当前的证候为</div>
            <div class="flex-row align-items" v-show="resultArr.length">
              <div
                class="newre-btn"
                v-for="(item, index) in resultArr"
                :key="index"
                :class="{ active: !resultArr.length }"
              >
                {{ item }}
              </div>
            </div>
            <div class="flex-row align-items" v-show="!resultArr.length">
              <div class="newre-btn active">健康</div>
            </div>
          </div>
          <div class="newre-tip" v-show="score <= 40">
            建议您去医院做进一步检查！
          </div>
          <div class="newre-tip" v-show="score > 40 && score < 80">
            建议您每周评估一次，密切关注自己的身体状态，及时进行辨证调理，治未病。
          </div>
        </div>
        <img
          class="newre-img"
          src="../../public/assets/report/doctor.png"
          alt
        />
      </div>
    </div>
    <!-- 证候解释 -->
    <div class="white-bg" v-show="symptom.length">
      <div class="pad_1">
        <div class="newr-font">证候解析</div>
        <div class="flex-row align-items module-height">
          <div
            class="newre-read-title"
            :class="{
              great: resultArr && resultArr.length == 0,
              active: btnIndex1 == index,
            }"
            v-for="(item, index) in symptom"
            @click="btnClik(index, 1)"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-for="(item, index) in symptom" :key="index + 'a'">
          <div
            class="newre-zh-box newre-background"
            v-show="btnIndex1 == index"
          >
            <div class="newre-zh-title">证候解释</div>
            <div class="newre-bottom">{{ item.explain }}</div>
            <div class="newre-zh-title" v-show="item.expression">常见表现</div>
            <div class="newre-bottom" v-show="item.expression">
              {{ item.expression }}
            </div>
            <div class="newre-zh-title" v-show="item.effect">
              易受影响的脏腑组织
            </div>
            <div class="newre-bottom" v-show="item.effect">
              {{ item.effect }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 身体质量 -->
    <div class="white-bg">
      <div class="pad_1">
        <div class="newr-font">体重指数 (BMI)</div>
        <div class="flex-row align-items newre-health-box">
          <div class="newre-health-item flex-column newre-health-myzhishu">
            <p class="newr-num">我的指数</p>
            <div class="newre-health-score">{{ MBI }}</div>
            <div
              class="newre-health-btn"
              :class="[
                { activebtn: MBItext == '正常' },
                { primarybtn: MBItext !== '正常' },
              ]"
            >
              {{ MBItext }}
            </div>
          </div>
          <div class="newre-health-item newre-maybe-weight flex-column">
            <p class="newr-num" style="margin-top: 2.2rem">我的适合体重</p>
            <div class="newre-health-score">
              {{ suitWeight }}
              <span
                style="color: #a1a1a1; margin-left: 0.5rem; font-weight: normal"
                >kg</span
              >
            </div>
          </div>
        </div>
        <div class="flex-row newre-health">
          <div>
            <p class="newr-num">偏瘦</p>
            <p class="newr-range">&gt; 18.5</p>
          </div>
          <div>
            <p class="newr-num">正常</p>
            <p class="newr-range">18.5~23.9</p>
          </div>
          <div>
            <p class="newr-num">偏胖</p>
            <p class="newr-range">24~26.9</p>
          </div>
          <div>
            <p class="newr-num">肥胖</p>
            <p class="newr-range">27~29.9</p>
          </div>
          <div>
            <p class="newr-num">重度肥胖</p>
            <p class="newr-range">≥30</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 易感疾病 -->
    <div class="white-bg" v-show="sickness.length">
      <div class="pad_1">
        <div class="newr-font">易感疾病</div>
        <div class="newre-zh-box newre-background mat2">
          <div v-for="(item, index) in sickness" :key="index">
            <div class="newre-zh-title">{{ item.name }}</div>
            <div class="newre-bottom">{{ item.sickness }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 药茶养生、艾灸养生、经络养生、药膳养生、六茶养生、经方、音乐养生、起居指导 -->
    <component
      :is="option"
      v-for="(option, index) in floatAlis"
      :content="content"
      :resultArr="resultArr"
      :floatAlis="floatAlis"
      :asset_prefix="imgPre"
      :key="index"
    >
    </component>
  </div>
  <div class="hu-ask">
    <div class="ask-box flex" v-if="page==1">
      <div class="ask-title flex-1">
          <div class="title-mark">健康指数</div>
          <div class="flex">
            <div class="fraction flex flex-1">
              <div  class="ask-orange"
              :class="[
                  { 'newre-red': score <= 40 && score!=0 },
                  { 'newre-great': score >= 80 },
              ]">{{ score }}</div>
              <div class="grade">分</div>
            </div>
            <div class="ask-info flex">
              <div class="info">性别：{{ sex == 2 ? "女" : "男" }}</div>
              <div class="info">年龄：{{ birth?birth+"岁":"暂无数据"}}</div>
              <div class="info">身高：{{ height }}cm</div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1">
                <div class="region">
                  <div class="region50">
                    <span> 0-50分</span>可能患有疾病，建议<span>就医检查</span>
                  </div>
                  <div class="region65">
                    <span>50-65分</span>身体极不平衡，建议<span>马上调理</span>
                  </div>
                  <div class="region80">
                    <span>65-80分</span>身体较不平衡，建议<span>马上调理</span>
                  </div>
                  <div class="region90">
                    <span>80-90分</span>身体较为平衡，建议<span>适当调理</span>
                  </div>
                  <div class="region100">
                    <span>90-100分 </span>身体平衡，建议<span>适当调理</span>
                  </div>
                </div>
                <div class="syndrome">
                  <div class="syndrome-title">当前的证候为</div>
                  <div class="flex">
                      <div
                        v-for="(item, index) in resultArr"
                        :key="index"
                        class="syndrome-name"
                        :class="{ active: !resultArr.length }"
                      >
                        {{ item }}
                      </div>
                  </div>
                  <div v-show="!resultArr.length && score!=0" class="flex">
                    <div class="syndrome-name syndrome-health">健康</div>
                  </div>
                  <div v-show="score <= 40 && score!=0" class="syndrome-censor">
                    建议您去医院做进一步检查！
                  </div>
                  <div v-show="score > 40 && score < 80" class="syndrome-censor">
                    建议您每周评估一次，密切关注自己的身体状态，及时进行辨证调理，治未病。
                  </div>
                </div>
            </div>
            <img
              src="../../public/assets/report/doctor1.png"
              class="ask-img"
              alt
            />
          </div>
          <div class="assess-top-left"></div>
          <div class="assess-top-right"></div>
          <div class="assess-bottom-left"></div>
          <div class="assess-bottom-right"></div>
          <div class="page-bottom-right">
            <div class="next" @click="pageFun(1)">下一页</div>
          </div>
      </div>
      <div class="page"></div>
    </div>
    <div v-if="page==2">
    <div class="ask-box flex">
      <div  class="syndrome-name syndrome-y"
        :class="{
          great: resultArr && resultArr.length == 0,
          active: btnIndex1 == index,
        }"
        v-for="(item, index) in symptom"
        @click="btnClik(index, 1)"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="ask-box no-ask flex" v-show="symptom.length">
      <div class="ask-title flex-1">
          <div v-for="(item, index) in symptom" :key="index + 'a'">
            <div
              v-show="btnIndex1 == index"
            >
              <div class="title-mark">证候解释</div>
              <div class="ask-common">
                <div class="ask-explain flex">
                    <div class="ask-explain-title" v-show="item.explain">证候解释</div>
                    <div class="ask-explain-text flex-1" v-show="item.explain">{{ item.explain }}</div>
                </div>
                <div class="ask-explain flex">
                    <div class="ask-explain-title" v-show="item.expression">常见表现</div>
                    <div class="ask-explain-text flex-1" v-show="item.expression">{{ item.expression }}</div>
                </div>
                <div class="ask-explain flex">
                    <div class="ask-explain-title"  v-show="item.effect">易受影响的脏腑组织</div>
                    <div class="ask-explain-text flex-1" v-show="item.effect">
                      {{ item.effect }}
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="assess-top-left"></div>
          <div class="assess-top-right"></div>
          <div class="assess-bottom-left"></div>
          <div class="assess-bottom-right"></div>
      </div>
      <div class="page"></div>
    </div>
    <div class="ask-box no-ask flex">
      <div class="ask-title flex-1">
          <div class="title-mark">体重指数 (BMI)</div>
          <div class="ask-common">
            <div class="ask-explain flex">
              <div class="ask-explain-title">我的指数</div>
              <div class="ask-explain-text">{{ MBI }}</div>
              <div class="flex flex-1 flex-y">
                <div class="askMbi" :class="{
                  healthy: MBItext=='健康',
                }">{{ MBItext }}</div>
              </div>
            </div>
            <div class="ask-explain flex">
              <div class="ask-explain-title">我的适合体重</div>
              <div class="ask-explain-text flex-1">{{ suitWeight }}kg</div>
            </div>
            <div class="ask-weight flex">
              <div class="weight flex flex-y flex-x">
                <div>
                  <p>偏瘦</p>
                  <p>&gt; 18.5</p>
                </div>
              </div>
              <div class="weight flex flex-y flex-x">
                <div>
                  <p>正常</p>
                  <p>18.5~23.9</p>
                </div>
              </div>
              <div class="weight flex flex-y flex-x">
                <div>
                  <p>偏胖</p>
                  <p>24~26.9</p>
                </div>
              </div>
              <div class="weight flex flex-y flex-x">
                <div>
                  <p>肥胖</p>
                  <p>27~29.9</p>
                </div>
              </div>
              <div class="weight flex flex-y flex-x">
                <div>
                  <p>重度肥胖</p>
                  <p>≥30</p>
                </div>
              </div>
            </div>
          </div>
          <div class="assess-top-left"></div>
          <div class="assess-top-right"></div>
          <div class="assess-bottom-left"></div>
          <div class="assess-bottom-right"></div>
          <div class="page-bottom-right">
            <div class="previou" @click="pageFun(-1)">上一页</div>
            <div class="next" @click="pageFun(1)">下一页</div>
          </div>
      </div>
      <div class="page"></div>
    </div>
    </div>
    <div class="ask-box flex" v-if="page==3 ">
      <div class="ask-title flex-1">
          <div class="title-mark">易感疾病</div>
          <div class="ask-common">
            <div class="ask-explain flex" v-for="(item, index) in sickness" :key="index">
              <div class="ask-explain-title">{{ item.name }}</div>
              <div class="ask-explain-text flex-1">{{ item.sickness }}</div>
            </div>
            <div v-if="sickness.length==0" class="ask-explain-title">无</div>
          </div>
          <div class="assess-top-left"></div>
          <div class="assess-top-right"></div>
          <div class="assess-bottom-left"></div>
          <div class="assess-bottom-right"></div>
      </div>
      <div class="page"></div>
    </div>
    <component
      :is="option"
      v-for="(option, index) in floatAlis"
      :content="content"
      :resultArr="resultArr"
      :floatAlis="floatAlis"
      :asset_prefix="imgPre"
      :key="index"
      :page="page"
      @pageFun="pageFun"
    >
    </component>
  </div>
</div>
</template>
<script>
import music from "../components/newReport/music";
import sixTea from "../components/newReport/sixTea";
import food from "../components/newReport/food";
import tea from "../components/newReport/tea";
import aijiu from "../components/newReport/aijiu";
import jingluo from "../components/newReport/jingluo";
import prescript from "../components/newReport/prescript";
import guide from "../components/newReport/guide";
import "./new_report.scss";
import { getHistory, getReportInfo } from "@/services/api";
export default {
  data() {
    return {
      info: {},
      content: {},
      history: [],
      resultArr: [],
      score: 0,
      btnIndex1: 0,
      floatAlis: [],
      page:1,
      white:this.$route.query.white
    };
  },
  components: { music, sixTea, food, tea, aijiu, jingluo, prescript, guide },
  computed: {
    principle: function () {
      const solutions = this.content.solutions;
      const data = [];
      for (const item in solutions.health_principle) {
        data.push(solutions.health_principle[item].content[0]);
      }
      return data;
    },
    definition: function () {
      const solutions = this.content.solutions;
      const data = {};
      for (const item in solutions.symptom_definition) {
        data[item] = {
          symbol: item,
          content: solutions.symptom_definition[item].content[0],
        };
      }
      return data;
    },
    //证候解析
    symptom() {
      var arr = [];
      if (this.content.symptom) {
        let data = this.content.symptom;
        for (let n in data) {
          var obj = {};
          obj.name = data[n].title;
          obj.explain = data[n].explain;
          obj.expression = data[n].expression;
          obj.effect = data[n].effect;
          arr.push(obj);
        }
        return arr;
      } else {
        return arr;
      }
    },
    // 易感疾病
    sickness() {
      var arr = [];
      if (this.content.sickness) {
        let data = this.content.sickness;
        for (let n in data) {
          var obj = {};
          obj.name = data[n].symbol_title;
          obj.sickness = data[n].sickness;
          arr.push(obj);
        }
        return arr;
      } else {
        return arr;
      }
    },
  },
  mounted() {
    getReportInfo(this.$route.query.user_id, this.$route.query.id).then(
      (res) => {
        if (res.code === 0) {
          var str =
            res.data.weight / ((res.data.height * res.data.height) / 10000);
          var h1 =
            ((res.data.height * res.data.height) / 10000).toFixed(2) * 18.5;
          var h2 =
            ((res.data.height * res.data.height) / 10000).toFixed(2) * 23.9;
          this.suitWeight = (
            (22 * (res.data.height * res.data.height)) /
            10000
          ).toFixed(1);
          this.MBI = str.toFixed(1);
          if (this.MBI <= 18.5) {
            this.MBItext = "偏瘦";
          } else if (this.MBI >= 18.5 && this.MBI <= 23.9) {
            this.MBItext = "正常";
          } else if (this.MBI >= 24 && this.MBI <= 26.9) {
            this.MBItext = "偏胖";
          } else if (this.MBI >= 27 && this.MBI <= 29.9) {
            this.MBItext = "肥胖";
          } else if (this.MBI >= 30) {
            this.MBItext = "重度肥胖";
          }
          this.sex = res.data.sex;
          this.height = res.data.height;
          this.birth = this.getAges(res.data.age);
          this.score = res.data.score;
          this.content = JSON.parse(res.data.content);
          this.floatAlis=[]
          for (let item in this.content) {
            if (
              item == "tea" ||
              item == "aijiu" ||
              item == "jingluo" ||
              item == "food" ||
              item == "prescript" ||
              item == "music" ||
              item == "guide"
            ) {
              this.floatAlis.push(item);
            }else if(item == "six_tea"){
              this.floatAlis.push("sixTea");
            }
          }
          this.resultArr = this.content.sick_text.split(",");
          this.imgPre = this.content.prefix_image;
          this.info = res.data;
        }
      }
    );
    getHistory(this.$route.query.user_id).then((data) => {
      if (data.code === 0) {
        this.history = data.data.list;
      }
    });
  },
  methods: {
    btnClik(index) {
      this.btnIndex1 = index;
    },
    pageFun(page){
      this.page = this.page+page;
      console.log(this.page);
    },
    //出生年月日算年龄
    getAges(str) {
      var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
      if (r == null) return false;
      var d = new Date(r[1], r[3] - 1, r[4]);
      if (
        d.getFullYear() == r[1] &&
        d.getMonth() + 1 == r[3] &&
        d.getDate() == r[4]
      ) {
        var Y = new Date().getFullYear();
        return Y - r[1];
      }
      return "输入的日期格式错误!";
    },
    //返回上一级
    onClickLeft: function () {
      this.$router.go(-1);
    },
  },
};
</script>
