<template>
  <!-- 经典药方 -->
  <div class="white-bg" v-show="prescript.length" name="prescript">
    <div class="pad_1">
      <div class="newr-font">经典药方</div>
      <div class="flex-row align-items module-height">
        <div
          class="newre-read-title"
          :class="{
            great: resultArr && resultArr.length == 0,
            active: btnIndex5 == index,
          }"
          v-for="(item, index) in prescript"
          :key="index"
          @click="btnClik(index, 5)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        class="rd-plan-item rd-content"
        v-for="(item, index) in prescript"
        :key="index"
        v-show="btnIndex5 == index"
      >
        <div class="item" v-for="paper in item.value" :key="paper.name">
          <div class="newre-zh-box newre-background">
            <div class="newre-hl-tea-title">{{ paper.title }}</div>
            <div class="newre-zh-title" v-show="paper.component">药物组成</div>
            <div class="newre-bottom" v-show="paper.component">{{ paper.component }}</div>
            <div class="newre-zh-title" v-show="paper.prescrip">经方功效</div>
            <div class="newre-bottom" v-show="paper.prescrip">{{ paper.prescrip }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hu-ask" v-if="page==numFun">
      
      <div class="ask-box">
        <div class="flex">
          <div class="syndrome-name syndrome-y" :class="{
              great: resultArr && resultArr.length == 0,
              active: btnIndex5 == index,
            }"
            v-for="(item, index) in prescript"
            :key="index"
            @click="btnClik(index, 5)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ask-box no-ask flex">
          <div class="ask-title flex-1">
              <div class="title-mark">经典药方</div>
              <div v-for="(item, index) in prescript"
                :key="index"
                v-show="btnIndex5 == index"
              >
                <div v-for="paper in item.value" :key="paper.name">
                    <div class="ask-tea flex">
                      <div class="syndrome-name syndrome-tea">{{ paper.title }}</div>
                    </div>
                    <div class="ask-explain flex">
                        <div v-show="paper.component" class="ask-explain-title">药物组成</div>
                        <div v-show="paper.component" class="ask-explain-text flex-1">{{ paper.component }}</div>
                    </div>
                    <div class="ask-explain flex">
                        <div v-show="paper.component" class="ask-explain-title">经方功效</div>
                        <div v-show="paper.component" class="ask-explain-text flex-1">{{ paper.prescrip }}</div>
                    </div>
                <div>
              </div>
            </div>
            </div>
            <div class="assess-top-left"></div>
            <div class="assess-top-right"></div>
            <div class="assess-bottom-left"></div>
            <div class="assess-bottom-right"></div>
            <div class="page-bottom-right">
              <div class="previou" @click="pageFun(-1)">上一页</div>
              <div class="next" @click="pageFun(1)">下一页</div>
            </div>
        </div>
        <div class="page"></div>
      </div>
  </div>
</template>
<script>
export default {
  props: ["resultArr", "content", "asset_prefix","page","floatAlis"],
  data() {
    return {
      btnIndex5: 0,
    };
  },
  computed: {
    //经典药方
    prescript() {
      let arr = [];
      if (this.content) {
        if (this.content.prescript) {
          let data = this.content.prescript;
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
    numFun(){
      let num;
      this.floatAlis.forEach((alis,index)=>{
        if(alis=='prescript'){
          num = index+3;
        }
      })
      return num;
    }
  },
  methods: {
    btnClik(index, type) {
      this.btnIndex5 = index;
    },
    goShop(item) {
      window.location.href = item;
    },
    pageFun(page){
      this.$emit('pageFun',page)
    }
  },
};
</script>
