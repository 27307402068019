<template>
      <!-- 六茶养生 -->
    <div class="white-bg" v-show="six_tea.length" name="six_tea">
      <div class="pad_1">
        <div class="newr-font">六茶养生</div>
        <div class="flex-row align-items module-height">
          <div
            @click="btnClik(index, 10)"
            v-for="(item, index) in six_tea"
            class="newre-read-title"
            :key="index"
            :class="{
              great: resultArr && resultArr.length == 0,
              active: btnIndex10 == index
            }"
          >
            {{ item.name }}
          </div>
        </div>
        <tea
        v-show="btnIndex10 == index"
        v-for="(item, index) in six_tea"
        :key="index"
        :item="item"
        :content="resultArr"
        :datalist="item.value"
        :asset_prefix="asset_prefix"
        :cpage="cpage"
        :cIndex="index"
      >
      </tea>
      </div>
    </div>
    <div class="hu-ask" v-if="page==numFun">
      <div class="ask-box">
        <div class="flex">
          <div class="syndrome-name syndrome-y"
            @click="btnClik(index, 10)"
            v-for="(item, index) in six_tea"
            :key="index"
            :class="{
              great: resultArr && resultArr.length == 0,
              active: btnIndex10 == index
            }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ask-box no-ask flex">
        <div class="ask-title flex-1">
            <div class="title-mark">六茶养生</div>
            <tea
              v-show="btnIndex10 == index"
              v-for="(item, index) in six_tea"
              :key="index"
              :item="item"
              :content="resultArr"
              :datalist="item.value"
              :asset_prefix="asset_prefix"
              :cpage="cpage"
              :cIndex="index"
            >
            </tea>
            <div class="assess-top-left"></div>
            <div class="assess-top-right"></div>
            <div class="assess-bottom-left"></div>
            <div class="assess-bottom-right"></div>
            <div class="page-bottom-right">
              <div class="previou" @click="pageFun(-1)">上一页</div>
            <div class="next" @click="pageFun(1)">下一页</div>
            </div>
        </div>
        <div class="page"></div>
      </div>
    </div>
</template>
<script>
import tea from "./teachild";
export default {
  components: { tea },
  props: ["resultArr", "content","asset_prefix","page","floatAlis"],
  data() {
    return {
      btnIndex4: 0,
      btnIndex5: 0,
      btnIndex6: 0,
      btnIndex7: 0,
      btnIndex8: 0,
      btnIndex9: 0,
      btnIndex10: 0,
      cpage:0
    };
  },
  computed: {
    //六茶养生
    six_tea() {
      let arr = [];
      if (this.content) {
        if (this.content.six_tea) {
          let data = this.content.six_tea;
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
    numFun(){
      let num;
      this.floatAlis.forEach((alis,index)=>{
        if(alis=='sixTea'){
          num = index+3;
        }
      })
      return num;
    }
  },
  methods: {
    btnClik(index, type) {
      if (type == 4) {
        this.btnIndex4 = index;
      } else if (type == 5) {
        this.btnIndex5 = index;
      } else if (type == 6) {
        this.btnIndex6 = index;
      } else if (type == 7) {
        this.btnIndex7 = index;
      } else if (type == 8) {
        this.btnIndex8 = index;
      } else if (type == 9) {
        this.btnIndex9 = index;
      } else if (type == 10) {
        this.btnIndex10 = index;
      }
    },
    pageFun(page){
      if(this.$props.resultArr.length>1){
          if(this.cpage+page<this.$props.resultArr.length && this.cpage+page>=0){
              this.cpage = this.cpage+page;
          }else{
            this.$emit('pageFun',page)
          }
      }else{
          this.$emit('pageFun',page)
      }  
    }
  }
};
</script>
