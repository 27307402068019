<template>
  <div>
    <!-- 药膳调理 -->
    <div class="white-bg" v-show="food.length" name="food">
      <div class="pad_1">
        <div class="newr-font">药膳调理</div>
        <div class="flex-row align-items module-height">
          <div
            class="newre-read-title"
            :class="{
              great: resultArr && resultArr.length == 0,
              active: btnIndex6 == index
            }"
            v-for="(item, index) in food"
            :key="index"
            @click="btnClik(index, 6)"
          >
            {{ item.name }}
          </div>
        </div>

        <div
          class="rd-plan-item rd-content"
          v-for="(item, index) in food"
          :key="index"
          v-show="btnIndex6 == index"
        >
         <div v-for="(item, index) in item.value" :key="index">
           <div class="newre-zh-box newre-background">
            <div class="newre-hl-tea-title">{{ item.title}}</div>
            <div class="newre-zh-title">药膳配料</div>
            <div class="newre-bottom">{{ item.material }}</div>
            <div class="newre-zh-title">烹煮方法</div>
            <div class="newre-bottom">{{ item.method }}</div>
            <img 
            class="food-img"
            :src="asset_prefix+item.images" alt="">
            
          </div>
         </div>
          
        </div>
      </div>
    </div>
    <div class="hu-ask" v-if="page==numFun">
      <div class="ask-box">
        <div class="flex">
          <div class="syndrome-name syndrome-y"
            :class="{
              great: resultArr && resultArr.length == 0,
              active: btnIndex6 == index
            }"
            v-for="(item, index) in food"
            :key="index"
            @click="btnClik(index, 6)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ask-box flex">
        <div class="ask-title flex-1">
            <div class="title-mark">药膳调理</div>
            
            <div v-for="(item, index) in food"
              :key="index"
              v-show="btnIndex6 == index"
              class="dishes-top"
            >
              <div v-for="(item, index) in item.value" :key="index">
                <div class="ask-tea flex">
                  <div class="syndrome-name syndrome-tea">{{ item.title}}</div>
                </div>
                <div class="dishes-cook flex">
                    <div class="flex flex-y">
                        <img :src="asset_prefix+item.images" alt="" class="dishes-png"/>
                    </div>
                    <div class="flex-1">
                        <div class="effect-title">烹煮方法</div>
                        <div class="effect-content">{{ item.method }}</div>
                    </div>
                </div>
                <div class="effect-box">
                    <div class="effect-title">药膳配料</div>
                    <div class="effect-content">{{ item.material }}</div>
                </div>
              </div>
            </div>
            <div class="assess-top-left"></div>
            <div class="assess-top-right"></div>
            <div class="assess-bottom-left"></div>
            <div class="assess-bottom-right"></div>
            <div class="page-bottom-right">
              <div class="previou" @click="pageFun(-1)">上一页</div>
              <div class="next" @click="pageFun(1)">下一页</div>
            </div>
        </div>
        <div class="page"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["resultArr", "content","asset_prefix","page","floatAlis"],
  data() {
    return {
      btnIndex6: 0,
    };
  },
  computed: {
  
    //药膳调养
    food() {
       let arr = [];
      if (this.content) {
        if (this.content.food) {
          let data = this.content.food;
          for (let key in data) {
            let obj = { value: [] };
            obj.name = key;
            for (let num in data[key]) {
              obj.value.push(data[key][num]);
            }
            arr.push(obj);
          }
        }
        return arr;
      } else {
        return arr;
      }
    },
    numFun(){
      let num;
      this.floatAlis.forEach((alis,index)=>{
        if(alis=='food'){
          num = index+3;
        }
      })
      return num;
    }
  },
  methods: {
    btnClik(index, type) {
      this.btnIndex6 = index;
    },
    pageFun(page){
      this.$emit('pageFun',page)
    }
  }
};
</script>
